import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../../containers/layout';
import { ImprintTemplate } from '../../pageTemplates/imprint';

const Imprint = ({ data }) => {
  return (
    <Layout>
      <ImprintTemplate data={data} />
    </Layout>
  );
};

export const query = graphql`
  query ImprintEN {
    strapi {
      imprint {
        localizations {
          text
          subheader
          meta {
            title
            description
          }
        }
      }
    }
  }
`;

export default Imprint;
